html, body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #f0f2f5 !important; 
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  line-height: 1.5715;
  font-variant: tabular-nums;
}

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

ul {
  padding-inline-start: 20px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}

p {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 15px;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-dark {
  color: #222;
}

.sub-title-dark {
  color: #666;
}

.sub-title-light-dark {
  color: #9d9d9d;
}


.title-light {
  color: #fff;
}

.sub-title-light {
  color: #fff;
}

.disable {
  .title-dark {
    color: #aaa;
  }
  .sub-title-dark {
    color: #aaa;
  }
}

.button-container {
  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-layout {
  height: 100vh;
}

.ant-form-item label {
  margin-left: 8px;
  font-weight: 600;
  padding-left: 5px;
}

.ant-form-item-required::before {
  left: -5px;
  top: 2px;
  position: absolute;
}

.collapsed {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.word-wrap {
  // overflow: hidden;
  // text-overflow:ellipsis;
  white-space: break-spaces !important;
}

.break-all {
  word-break: break-all;
}

.box-shadow {
  border: 1px solid #efefef;
  box-shadow: 1px 1px 4px 1px #e7e7e7;
  border-radius: 4px;
}

.warning-slider .ant-slider-track {
  background-color: orange;
}

.warning-slider:hover .ant-slider-track {
  background-color: orange!important;
}

.safe-slider .ant-slider-track {
  background-color: green;
}

.safe-slider:hover .ant-slider-track {
  background-color: green!important;
}

.border-container {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 20px 40px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mx-1 {
  margin-left: 10px;
  margin-right: 10px;
}

.green-btn {
  background-color: #63B963 !important;
  border-color: #63B963 !important;
  color: #fff !important;
}

.green-btn:hover {
  background-color: #85cd85 !important;
  border-color: #85cd85 !important;
}

.d-flex {
  display: flex;
  align-items: center;
}

//ant tabs
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 4px 0 0 !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #4cb0ef !important;
  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}